import { memo, useCallback, useState, useEffect, useRef } from 'react';

import * as Filtrator from '@Stores/Filtrator';
import ModalSidebar from '@Modals/_components/ModalSidebar';
import * as VoiceOfCustomersStore from '@Stores/VoiceOfCustomers';
import useDeps from '@Contexts/DI/useDeps';
import useModalWindowTranslation from '@Queries/useModalWindowTranslation';
import VoiceOfCustomers from './elements/VoiceOfCustomers';
import Footer from './elements/Footer';
import Content from './elements/Content';
import styles from './FiltersModal.module.css';

import type { FC, MouseEvent } from 'react';
import type { ModalSidebarProps } from '@Modals/_components/ModalSidebar';
import type { FilterView } from '@Types/Filters';

export interface FiltersModalData {
  selectedFilterId?: string;
  entityId: 'catalog' | 'textile' | 'constructor';
  maxWidth?: number;
  filterView?: FilterView;
  needVoc?: boolean;
  isOnProductPage?: boolean;
  onApply?: (e: MouseEvent) => void;
  onReset?: (e: MouseEvent) => void;
  onChangeFilters?: () => Promise<void>;
}

export type FiltersModalProps = ModalSidebarProps<FiltersModalData>;

const formatName = (name: string) => name.replace(/ /g, '');

const FiltersModal: FC<FiltersModalProps> = (props) => {
  const { modal, onClose, ...restProps } = props;
  const {
    selectedFilterId,
    entityId,
    maxWidth,
    onApply,
    onReset,
    onChangeFilters,
    filterView,
    needVoc = true,
    isOnProductPage,
  } = modal.data;
  const idVoc = 'categoryFilter';
  const isViewSticky = filterView === 'sticky';
  const quiz = VoiceOfCustomersStore.useUserPoll(idVoc);
  const { t, isPending } = useModalWindowTranslation({ modalId: modal.id });
  const [scrollTop, setScrollTop] = useState(null);
  const refContent = useRef<HTMLDivElement>();
  const { analytics } = useDeps();

  const handleOpen = useCallback(() => {
    if (!refContent.current) return;
    if (!selectedFilterId) return;

    const id = `#${formatName(selectedFilterId)}`;
    const groupElem: HTMLDivElement = refContent.current.querySelector(id);

    if (!groupElem) return;

    // Если это первая группа, то смещение не нужно, 30 это отступ до первой группы
    setScrollTop(groupElem.offsetTop === 30 ? 0 : groupElem.offsetTop);
    setTimeout(() => setScrollTop(undefined));
  }, [selectedFilterId]);

  const handleClose = useCallback(
    (e: MouseEvent | KeyboardEvent) => {
      analytics.dispatchEvent('filters.close', {
        label: selectedFilterId === 'all' ? 'Все фильтры' : selectedFilterId,
        status: isViewSticky ? 'закреп' : 'не закреп',
        isOnProductPage,
      });
      Filtrator.resetUnapplied(entityId);

      if (onChangeFilters) {
        setTimeout(() => {
          onChangeFilters();
        });
      }
      if (onClose) onClose(e);
    },
    [
      analytics,
      selectedFilterId,
      isViewSticky,
      entityId,
      onChangeFilters,
      onClose,
      isOnProductPage,
    ],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => handleOpen(), []);

  if (isPending) return null;

  return (
    <ModalSidebar
      {...restProps}
      modal={modal}
      title={t('filter')}
      view='fullscreen'
      scrollTop={scrollTop}
      maxWidth={maxWidth}
      cnWrapperContent={styles.wrapper}
      cnContent={styles.content}
      footer={
        <Footer
          entityId={entityId}
          onApply={onApply}
          onReset={onReset}
          selectedFilterId={selectedFilterId}
          filterView={filterView}
          isOnProductPage={isOnProductPage}
        />
      }
      bordered
      onClose={handleClose}
    >
      <div ref={refContent}>
        <Content
          entityId={entityId}
          selectedFilterId={selectedFilterId}
          onChangeFilters={onChangeFilters}
        />
        {quiz?.active && needVoc && (
          <VoiceOfCustomers
            quiz={quiz}
            selectedFilterId={selectedFilterId}
            filterView={filterView}
          />
        )}
      </div>
    </ModalSidebar>
  );
};

export default memo(FiltersModal);
