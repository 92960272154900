import { createStore, getValue, update } from '@kundinos/nanostores';
import { useStore } from '@kundinos/nanostores/react';
import { useCallback } from 'react';

import * as Meta from '@Queries/Meta';
import useDevice from '@Queries/useDevice';
import ApiSite from '@Api/Site';
import di from '@Domain/di';
import setCookie from '@Utils/setCookie';
import getCookie from '@Utils/getCookie';

import type { UserPollData } from '@Api/Site/userPolls';
import type { BodyVOCData } from '@Types/VoiceOfCustomers';
import type { ResponseAddVoc } from '@Api/Site/addVoc';

export interface ResultData {
  rating?: number;
  message?: string;
}

export type PollId = 'categoryVoc' | 'categoryFilter';

export interface QuizData extends UserPollData {
  name: PollId;
  active?: boolean;
  viewed?: boolean;
  slug: string;
  categoryName: string;
  isModels: boolean;
  result?: ResultData;
  error?: boolean;
}

const voiceOfCustomersStore = createStore<QuizData[]>(() => {
  voiceOfCustomersStore.set([]);
});

export const init = (data: QuizData[]) => {
  const initialData = data.map((item) => ({
    ...item,
    active: false,
    viewed: item.cookie ? !!getCookie(item.cookie.name) : false,
    result: null,
    error: false,
  }));

  update(voiceOfCustomersStore, () => initialData);
};

export const clear = (id: PollId) => {
  update(voiceOfCustomersStore, (data) => {
    return data.filter((elem) => elem.name !== id);
  });
};

export const open = (id: PollId) => {
  update(voiceOfCustomersStore, (data) => {
    return data.map((elem) => {
      if (elem.name === id) {
        return { ...elem, active: true };
      }
      return elem;
    });
  });
};

export const setResult = ({ rating, message }: ResultData, id: PollId) => {
  update(voiceOfCustomersStore, (data) => {
    return data.map((elem) => {
      if (elem.name === id) {
        let result = { ...elem.result };

        if (rating) {
          result = { ...result, rating };
        }
        if (typeof message === 'string') {
          result = { ...result, message };
        }

        return { ...elem, result };
      }
      return elem;
    });
  });
};

export const setError = (isError: boolean, id: PollId) => {
  update(voiceOfCustomersStore, (data) => {
    return data.map((elem) => {
      if (elem.name === id) {
        return { ...elem, error: isError };
      }
      return elem;
    });
  });
};

export const close = (id: PollId) => {
  update(voiceOfCustomersStore, (data) => {
    return data.map((elem) => {
      if (elem.name === id) {
        return { ...elem, active: false, viewed: true };
      }
      return elem;
    });
  });
};

export function useUserPoll(id: PollId) {
  const voiceOfCustomers = useStore(voiceOfCustomersStore);

  return voiceOfCustomers.find((item) => item.name === id);
}

export const useSubmit = (id: PollId) => {
  const meta = Meta.useMeta();
  const device = useDevice();
  const poll = useUserPoll(id);
  const { logger } = di.getDeps();

  const submit = useCallback(async () => {
    const { result, endpoint } = getValue(voiceOfCustomersStore).find((elem) => elem.name === id);
    const body: BodyVOCData = {
      rating: result?.rating,
      message: result?.message || '',
      region: meta.data?.region.name,
      categoryName: poll.categoryName,
      translite: poll.slug,
      url: window.location.href,
      viewType: poll.isModels ? 'model' : 'list',
      viewportWidth: window.innerWidth,
      viewportHeight: window.innerHeight,
      browser: device.data.client.name,
    };

    try {
      const res: ResponseAddVoc = await ApiSite.addVoc(body, endpoint);

      if (res.ok) {
        update(voiceOfCustomersStore, (data) => {
          return data.map((elem) => {
            if (elem.name === id) {
              return { ...elem, viewed: true };
            }
            return elem;
          });
        });
      }
    } catch (err) {
      logger.log(err);
    }
  }, [meta.data, poll, device, id, logger]);

  return { submit };
};

voiceOfCustomersStore.listen((popup) => {
  if (!popup.length) return;
  popup.forEach((item) => {
    if (item.name === 'categoryVoc' && !!item.cookie) {
      if (item.viewed) {
        setCookie(`${item.cookie.name}`, '1', {
          expires: item.cookie.bestBefore,
        });
      }
    }
  });
});
